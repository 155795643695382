.bar {
    position: absolute;
    display: flex;
    height: 61px;
    top: 0px;
    left: 0px;
    right: 5px;
    background: #003C89;
    align-items: center;
    padding: 0 15px;
}

.area{
    padding-top: 61px;
    height: calc(100vh - 61px);
    overflow-x: hidden;
    overflow-y: scroll;
}

body {
    overflow-x: hidden;
    overflow-y: hidden;
}
.dropdown {
    display: inline-block;
    width: 38.33px;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    width: 60px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
    text-align: center;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  } 
.area::-webkit-scrollbar {
    width: .4em;
}
   
.area::-webkit-scrollbar-thumb {
    background-color: #003C89;
}

.bar > .logo{
    cursor: pointer;
    height: 70%;
}
.accountIcon{
    width: 38.33px;
    height: 38.33px;
    top: 11px;
    right: 10px;
}
.searchbar {
    min-height: 41px;
    margin-left: 225px;
    margin-right: 55px;
    margin-top: 8px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
}
.searchbar > input{
    width: auto;
    min-height: 41px;
    border-radius: 4px;
    border: 0;
    outline: 0;
    background: #EBF0F7 url(../../assets/images/lens.png) no-repeat;
    background-position: right 10px top 8px;
    padding-right: 40px;
}

.welcomeArea {
    background-image: url('../../assets/images/mainBG.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width:100%;
    height: auto;
    min-height: 557px;
    left: 0px;
    display: flex;
    flex-wrap: wrap;
    flex: 1 2 0;
    padding-bottom: 40px;
}

.welcomeArea > .lastcourse{
    min-width: 350px;
    max-width: 877px;
    height: 100%;
    top: 32px;
    overflow: hidden;
    flex: 1 2 0;
    display: flex;
    flex-direction: column;
    padding-left: 46px;
    padding-top: 32px;
}

.lastcourse > h1{
    font-family: 'Manrope';
    width: calc(100%-46px);
    height: auto;
    top: 32px;
    font-weight: 700;
    font-size: 34px;     
    margin-top: 0;
}

.lastcourse > h1 > span{
    color: #197DFF;
}

.lastcourse > .videoThumbnail{
    position: relative;
}

.videoThumbnail > .playButton {
    width: 80px;
    height: 80px;
    border: none;
    position: absolute;
    right: 26px;
    bottom: 20px;
    cursor: pointer;
}

.videoThumbnail > h2 {
    width: calc(100% - 142px);
    min-height: 66px;
    border: none;
    position: absolute;
    margin-bottom: 0;
    left: 26px;
    bottom: 20px;
    font-family: 'Manrope';
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    display: flex;
    align-items: flex-start;
    color: white;
}

.welcomeArea > .lastcourses{
    min-width: 380px;
    max-height: 431px;
    right: 0;
    padding-top: 94px;
    flex: 1 2 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vertical-grid-container {
    /*display: grid;
    grid-template-columns: auto;*/
    gap: 11px;
    flex: 1 2 0;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    height: 100%;
    padding-left: 40px;
    padding-right: 40px;
}
.vertical-grid-container > h2{
    font-family: 'Manrope';
    width: 100%;
    height: 37px;
    font-weight: bold;
    font-size: 22px;    
    margin-top: 0;
    margin-bottom: 0;
}
.vertical-grid-container > .course {
    background-color: rgb(240, 240, 240);
    border-radius: 10px;
    height: 103px;  
    display: flex;   
    overflow: hidden;
    cursor: pointer;
}
.course > .textContainer{ 
    flex: 1 2 0;
    font-family: 'Manrope';    
    font-style: normal;
    font-weight: 700;  
    size: 22px;  
    padding-top: 10px; 
    padding-right: 17px;
    padding-bottom: 5px;
    display: flex;
    align-items:center;
    padding-left: 15px;
}
 .course > .img-course{
    width: 140px;
}
.courses{
    width: 100vw;
    min-height: 643px;
    background-color: rgb(255, 255, 255);
    padding-top: 25px;
    font-size: 18px;
}
.horizontal-grid-container{
    margin-top: 0px;
    margin-right: 50px;
    height: 500px;
    width: 100%;
    overflow: scroll;
    overflow-y: hidden;
    flex-direction: row;
    display: flex;    
}
.horizontal-grid-container::-webkit-scrollbar {
    height: 1em;
}
   
.horizontal-grid-container::-webkit-scrollbar-thumb {
    background-color: #003C89;
    border-radius: 5px;
}
.horizontal-grid-container > .courseContainer{    
    width: 386px;
    height: 455px;
    box-shadow: 5px 5px 5px 5px #aaaaaa;
    margin-top: 10px;
    margin-right: 18px;
    min-width: 386px;
    border-radius: 15px;
}
.courseContainer > .courseImage{
    width: 100%;
    min-height: 210px;
    max-height: 210px;
    border-radius: 15px;
}
.courseContainer > .courseTextTitle {
    width: 351px;
    height: 67px;
    margin-top: 15px;
    margin-left: 17px;
}
.courseTextTitle > div{
    font-family: 'Manrope';
    font-weight: 700;
    size: 24px;
}
.courseContainer > .students{
    background: url(../../assets/images/students_icon.png) no-repeat;
    background-size: 25.83px 25.83px;
    width: 351px;
    height: 27px;
    margin-top: 15px;
    margin-left: 17px;    
}
.students > div{
    font-family: 'Roboto';
    font-weight: 400;
    size: 18px;
    padding-left: 30px;
    padding-top: 3px;
    color: #8F5D00;
}
.courseContainer > .hours{
    background: url(../../assets/images/clock.png) no-repeat;
    background-size: 25.83px 25.83px;
    width: 351px;
    height: 27px;
    margin-top: 15px;
    margin-left: 17px;
}
.hours > div{
    font-family: 'Roboto';
    font-weight: 400;
    size: 18px;
    padding-left: 30px;
    padding-top: 3px;
    color: #8F5D00;
}
.courseContainer > .buttonCourse{
    background-color: #197DFF;
    width: 351px;
    height: 50px;
    margin-top: 15px;
    margin-left: 17px;
    border-radius: 10px;
    cursor: pointer;
}
.buttonCourse > div{
    font-family: 'Manrope';
    font-weight: 700;
    size: 24px;
    color: #ffffff;
    text-align: center;
    padding-top: 14px;
}
.react-tabs__tab--selected{
    border-color: #003C89 !important;
}
.tab{
    font-family: 'Manrope';
    font-size: 18px;
    font-weight: 400px;
    padding:10px 20px;
    margin:0px 5px;
    border-bottom: 5px solid #aaa;
    display:inline-block;
    transition: 0.25s ease;
    cursor: pointer;
}
.demoButton{
    width: 250px;
    height: 70px;
    background-color: #197DFF;
    border-radius: 7px;
}
.demoButton > div{
    background: url(../../assets/images/edit_icon.png) no-repeat;
    background-size: 25px 25px;
    background-position: 50px 20px;
    font-family: 'Manrope';
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    padding-top: 17px;
    color: white;    
    
}
.grid-table-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    padding: 10px;
    max-width: 94%;
  }
  .grid-table-container > input {
    font-size: 30px;
    text-align: center;
    width: 100%;
  }
  .mycourses{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
  .mycourses > .myCourseContainer{
    border: 1px solid rgba(141, 141, 141, 0.8);
    box-shadow: 5px 5px 5px #aaaaaa;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 20px;
    max-width: 350px;
    min-width: 350px;
  }
  .myCourseContainer > .text-mycourses-container{
    width: auto;
    padding: 0;
    height: 130px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
  }
  .text-mycourses-container > div{
    font-family: 'Manrope';
    text-align: center;
    font-weight: bold;
    font-size: 24px;
  }
  .myCourseContainer > .button-mycourses-container{
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    width: auto;
    height: 50px;
    background-color: #197DFF;
    border-radius: 7px;
    cursor: pointer;
    
  }
  .button-mycourses-container > div{
    font-family: 'Manrope';
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    padding-top: 10px;
    color: white;   
  }
  .main-tabs{
    padding-top: 35px;
    padding-left: 50px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
}
.main-tabs-title{
    font-family: 'Manrope';
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    padding-top: 29px;
    margin-bottom: 13px;
}
.grid-main-tabs{
    flex-direction: column;
    display: flex;
    position: relative;
}
.left-arrow{
    background-color: #197DFF;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0px;
    top: 260px;
    cursor: pointer;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    gap: 9px;
}
.right-arrow{
    background-color: #197DFF;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0px;
    top: 260px;
    cursor: pointer;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
}

.right-arrow > img {
    width: 25px;
    height: 40px;
}
.left-arrow > img{
    width: 25px;
    height: 40px;
}

@media (max-width:920px){
    .grid-main-tabs > .left-arrow{
        display: none !important;
    }
    .grid-main-tabs > .right-arrow{
        display: none !important;
    }
}

.add-myCourseContainer{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) inset;
    background-color: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope';
    font-weight: 700;
    font-style: bold;
    font-size: 20px;
    cursor: pointer;
    gap: 10px;
    width: 392.98px;
    height: 237.94px;
}
.add-myCourseContainer:hover{
    background-color: #eeeded;
}
.copyright{
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}
.copyright > .content-copyright{
    width: auto;
    height: auto;
    text-align: center;
    font-family: 'Roboto';
    font-weight: 300;
    font-style: normal;
    font-size: 18px;
}
.content-copyright > div{
    color: #8181817e;
}
.log-tab{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}