
.title-tab{
    background-color: #197DFF;
    width: auto;
    height: 25px;
}

.log-table-item{
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.164);
    padding: 10px 10px;
}

.log-table-row:hover {
    background-color: rgba(230, 228, 228, 0.726);
}

.save::-webkit-scrollbar {
    width: .4em;
    height: .5em;
}
   
.log-table::-webkit-scrollbar-thumb {
    background-color: #003C89;
    border-radius: 10px;
}
@media (min-width:920px){
    .save::-webkit-scrollbar{
        overflow-x: scroll;
    }
}