.professor-information-item{
    width: 100vw;
    height: 100vh;
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
}
.professor-information-background {
    background-color: #2727277c;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}
.professor-information-card{
    background-color: #ffffff;
    display: flex;
    padding: 25px;
    border-radius: 10px;
    flex-wrap: wrap;
    box-shadow: 10px 10px 10px #3a3a3a;
    z-index: 1;
    justify-content: center;
    align-items: center;  
    position: relative;
}
.professor-img-card{
    width: auto;
    height: auto;
    position: relative;
}
.professor-img-card-img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    max-height: 303px;
}
.professor-information-card > .professor-text-card{
    border-radius: 15px;
    display: inline;
    max-width: 80vw;
    max-height: 80vh;
    overflow-y: hidden;
    padding: 10px;
}

.professor-text-card::-webkit-scrollbar {
    width: .4em;
}
   
.professor-text-card::-webkit-scrollbar-thumb {
    background-color: #003C89;
    border-radius: 10px;
}
@media (max-width:920px){
    .professor-text-card::-webkit-scrollbar-thumb {
        display: none;
    }
}

.professor-name{
    font-style: 'Roboto';
    font-weight: 400;
    font-size: 18px;
}

.professor-special{
    font-style: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    color: #003C89;
}

.professor-info{
    background-color: #EBF0F7;
    padding: 15px;
    border-radius: 15px;
    margin-top: 15px;
    width: 400px;
    height: 180px;
    font-style: 'Roboto';
    font-weight: 400;
    font-size: 14px;
}

.professor-photo-button {
    position: absolute;
    top: 0;
    right: 12px;
}