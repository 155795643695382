.log-information-item{
    width: 100vw;
    height: 100vh;
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    gap: 20px;
}
.log-information-background {
    background-color: #2727277c;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}
.log-information-card{
    background-color: #ffffff;
    display: flex;
    padding: 25px;
    border-radius: 10px;
    flex-wrap: wrap;
    z-index: 1;
    justify-content: center;
    align-items: center;  
    position: relative;
}

