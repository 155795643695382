.above-box{
    display: flex;
    flex: 1 2 ;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    position: relative;
}

.course-tabs{
    padding-left: 50px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
}

.info-tab {
    display: none;
    width: 100%;
    flex-direction: column;
    padding-top: 35px;
    padding-bottom: 35px;
}

.modules-tab{
    display: none;
    width: 100%;
    flex-direction: column;
    gap: 15px;
    padding-top: 35px;
    padding-bottom: 35px;
}

.professors-tab{
    display: none;
    width: 100%;
    flex-wrap: wrap;
    gap: 15px;
    padding-top: 35px;
    padding-bottom: 35px;
}

.publish-tab{
    display: none;
    width: 100%;
    flex-wrap: wrap;
    gap: 15px;
    padding-top: 35px;
    padding-bottom: 35px;
}

.design-tab{
    display: none;
    width: 100%;
    flex-wrap: wrap;
    gap: 15px;
    padding-top: 35px;
    padding-bottom: 35px;
}

.log-tab {
    width: 100%;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.students-tab, .applicants-tab, .accepted-tab{
    display: none;
    width: 100%;
    flex-wrap: wrap;
    gap: 15px;
    padding-top: 35px;
    padding-bottom: 35px;
}

.react-tabs__tab-panel--selected{
    display: flex !important;
}

.accepted-students-button{
    width: 250px;
    height: 60px;
    background-color: #197DFF;
    border-radius: 10px;
    margin-top: 35px;
    cursor: pointer;
    
}
.accepted-students-button > div{
    color: #EBF2FC;
    font-family: 'Roboto';
    font-weight: 700;
    font-style: bold;
    font-size: 22px;
    text-align: center;
    padding-top: 16px;
}

.add-module{
    height: 62px;
}

.add-button{
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) inset;
    background-color: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope';
    font-weight: 700;
    font-style: bold;
    font-size: 20px;
    cursor: pointer;
    gap: 9px;
}

.add-button:hover{
    background-color: #eeeded;
}
@media (max-width:620px){
    .above-box{
        flex-direction: column;
    }
}