.addUser-information-item{
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 1;    
}

.addUser-information-background {
    background-color: #2727277c;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: -1;    
}
.addUser-information-card{
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 25px;
    box-shadow: 10px 10px 10px #3a3a3a;
    justify-content: center;
    align-items: center;  
    border-radius: 10px;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    font-family: 'Manrope';
    gap: 15px;
    position: relative;
}
.addUser-button{
    background-color: #197DFF;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;  
    height: 40px;
    border-radius: 10px;
    font-style: bold;
    font-weight: 700;
    font-size: 20px;
    margin-top: 15px;
    cursor: pointer;
    width: 230px;
}
.close-window{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}
