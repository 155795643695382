.publish-area {
    flex: 1 2;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.config-area {
    flex: 1 2;
}
.dates{
    text-align: center;
    padding-top: 30px;
}
.price-area{
    display: flex;
    align-items: flex-end;
    gap: 20px;
}
.publish-button{
    display: flex;
    background-color: #AFDB00;
    width: 200px;
    height: 50px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    cursor: pointer;
}
.publish-button > .publish-icon{
    width: 15px;
    height: 20px;
}
.publish-button > div{
    color: rgb(255, 255, 255);
    font-family: 'Roboto';
    font-style: normal;
    margin-top: 5px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;

}
.file-button{
    display: flex;
    background-color: #197DFF;
    width: 200px;
    height: 50px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    gap: 9px;
}
.file-button > .file-icon{
    width: 15px;
    height: 20px;
}
.file-button > div{
    color: rgb(255, 255, 255);
    font-family: 'Roboto';
    font-style: normal;
    margin-top: 5px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
}

.start-date-calendar{
    flex: 1 2;
    display: flex;
    gap: 20px;
    align-items: flex-end;
}

.custom-calendar{
    position: absolute;
    right: 0;
    z-index: 2;
}

.box select {
  background-color: #197DFF;
  color: white;
  width: 62px;
  height: 40px;
  border: none;
  font-size: 15px;
  -webkit-appearance: button;
  appearance: button;
  outline: none;
  border-radius: 10px;
}

.box::before {
  content: "\f13a";
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  text-align: center;
  font-size: 28px;
  line-height: 45px;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.box:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

.box select option {
  padding: 30px;
  background-color: rgb(255, 255, 255);
  color: black;
}

.erase-button-course{
    width: 200px;
    height: 50px;
    background-color: red;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;    
}
.erase-button-course > div{
    color: aliceblue;
    font-family: 'Manrope';
    font-style: bold;
    font-weight: 700;
    font-size: 20px;
}
.money{
    display: flex;
    justify-content: center;
    align-items: center; 
    gap: 5px;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 20px;
    font-style: normal;
    position: relative;
}
.money-icon{
    position: absolute;
    left: 10px;
}
.reverse-input{
    direction: RTL; 
    padding-right: 10px;
}

.duplicate-button-course{
    width: 200px;
    height: 50px;
    background-color: #197DFF;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;    
}
.duplicate-button-course > div{
    color: aliceblue;
    font-family: 'Manrope';
    font-style: bold;
    font-weight: 700;
    font-size: 20px;
}