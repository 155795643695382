.design-area {
    flex: 1 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 400px;
}

.design-input-area{
    display: flex;
    gap: 10px;
    flex: 1 2;
    flex-wrap: wrap;
}

.design-input-combo{
    flex-direction: column;
    display: flex;
    flex: 1 2;
    min-width: 400px;
}

.design-input-area > img{
    width: 193px;
    height: 105px;
}

.design-form-button-area {
    width: 193px;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.design-form-button{
    width: 100%;
    height: 56px;
    border: 0;
    border-radius: 10px;
    background-color: dodgerblue;
    color: white;
    font-size: 20px;
    font-family: 'Roboto';
    font-weight: 400;
}

.design-form-button:hover{
    background-color: rgb(15, 135, 255);
    color: white;
}

.design-form-button:active{
    background-color: rgb(0, 128, 255);
    color: white;
}