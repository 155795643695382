.content-box{
    border: 1px solid  #DB8F00;
    width: 223px;
    height: 280px;
    border-radius: 10px;
    top: 31px;
    margin-top: 31px;
}

.above-box > .left-content{
    min-width: 250px;
    max-width: 250px;
    flex: 1 2 0;
    height: auto;
}
.above-box > .right-content{
    flex: 1 2 0;
    display: flex;
    height: auto;
    max-width: calc(100vw - 340px);
}

.content-box > .title-box{
    font-family: 'Roboto';
    font-weight: 300;
    font-style: normal;
    size: 24px;
    padding-left: 17px;
    padding-top: 35px;
    color: #8F5D00;    
}
.content-box > .text-box-content{
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 25px;
    font-family: 'Roboto';
    font-weight: 300;
    font-style: normal;
    color: #8F5D00;
    display: flex;
}
.text-box-content > div{
    padding-left: 10px;
    font-family: 'Roboto';
    font-weight: 300;
    font-style: normal;
    color: #8F5D00;
}
.text-box-content > img{
    width: 20px;
    height: 20px;
}
@media (max-width:620px){
    .above-box > .right-content{
        flex: 1 2 0;
        display: flex;
        height: auto;
        max-width: calc(100vw - 75px);
    }
}