.professor{
    position: relative;
}

.professor-item {
    border: 1px solid rgba(141, 141, 141, 0.8);
    box-shadow: 5px 5px 5px #aaaaaa;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    width: 232px;
    cursor: pointer;
    position: relative;
}

.professor-item:hover {
    background-color: rgb(244, 244, 244);
}

.professor-item:active {
    background-color: rgb(234, 234, 234);
}

.professor-img-content{
    border-radius: 10px;
    width: 232px;
    height: 197px;
    display: flex;
}

.professor-img-content > img {
    object-fit: cover;
    object-position: 0px 1%;
    border-radius: 10px;
    width: 100%;
    height: 100%;    
}

.professor-item >.professor-title-content{
    width: auto;
    height: 43px;
    margin-top: 6px;
    margin-left: 6px;
    margin-right: 6px;
    display: flex;
    align-items: center;
}
.professors-content > div{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}
.professor-item > .professor-data{
    width: auto;
    height: 43px;
    margin-top: 6px;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 5px;
}

.professor-data > div{
    font-family: 'Roboto';
    font-style: normal;
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #003C89;
}

.add-professor-item {
    width: 232px;
    box-shadow: 5px 10px 5px rgb(185, 185, 185) inset !important;
}

.add-professor-icon{
    background: url(../../../assets/images/add_icon.png) no-repeat;
    width: 39px;
    height: 37px;   
}

.add-professor-text{
    font-family: 'Manrope';
    font-weight: 700;
    font-style: bold;
    font-size: 20px;
    height: 279px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.delete-professor{
    right: 13px;
    top: 10px;
    position: absolute;
    cursor: pointer;
    opacity: 0.7;
}